import {z} from "zod";

export const backgroundSchema = z.enum([
    "BRAND_DARK",
    "BRAND_LIGHT",
    "DEFAULT",
    "NEUTRAL_DARK",
    "NEUTRAL_LIGHT",
    "PRIMARY",
    "WHITE",
    "NONE",
]);

export enum ModuleBlockType {
    DIDACTIC_TOOLS = "DIDACTIC_TOOLS",
    VIDEO_BLOCK = "VIDEO_BLOCK",
    TITLE_BLOCK = "TITLE_BLOCK",
}

export enum ContentBlockType {
    CONTENT = "CONTENT",
    LIST = "LIST"
}

export type BlockType = ContentBlockType | ModuleBlockType

/**
 * Converts a string to a ModuleBlockTypeType or null if the string is not a valid ModuleBlockTypeType.
 */
export function convertToBlockTypeOrNull(type: string): BlockType | null {
    if(Object.values(ModuleBlockType).includes(type.toUpperCase() as ModuleBlockType)) {
        return (type.toUpperCase() as ModuleBlockType);
    }

    if(Object.values(ContentBlockType).includes(type.toUpperCase() as ContentBlockType)) {
        return (type.toUpperCase() as ContentBlockType);
    }
    return null;
}

export type ModuleBlockTypeType = keyof typeof ModuleBlockType;

export const baseBlockSchema = z.object({
    id: z.union([z.string().uuid(), z.number()]).optional().nullable(),
    order: z.number().optional(),
    type: z.enum(["CONTENT", ...Object.values(ModuleBlockType)]),
    label: z
        .object({
            id: z.string().uuid().optional(),
            label: z.string(),
        })
        .optional()
        .nullable()
        .default({label: ""}),
    background: backgroundSchema
        .optional()
        .transform((value) => (value === "PRIMARY" ? "BRAND_DARK" : value)),
    textBackground: backgroundSchema.optional(),
});

export type BackgroundType = z.infer<typeof backgroundSchema>;
