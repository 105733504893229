import { useTranslation } from "react-i18next";
import { Scope, usePermissionsStore } from "@/store/permissions";
import {
    Users02Icon,
    Settings04Icon,
    PresentationChart02,
} from "@/components/base/icons";

export function useMenuItems() {
    const { t } = useTranslation("common", { keyPrefix: "main-menu" });

    const { hasPermission } = usePermissionsStore();

    const hasSufficientGroupPermissions = [
        hasPermission(Scope.GROUPS_CREATE),
        hasPermission(Scope.GROUPS_UPDATE),
    ].every((permission) => permission);

    const hasSufficientRapportagePermissions = [
        hasPermission(Scope.GROUPS_READ),
        hasPermission(Scope.EDUCATIONAL_PROGRAMS_READ),
        hasPermission(Scope.GROUP_PROGRESS_READ),
    ].every((permission) => permission);

    const personalMenuItems = [
        {
            icon: <Settings04Icon className="h-5 w-5 shrink-0" />,
            label: t("user-menu.my-account"),
            href: "/profile/personal",
        },
    ];

    const baseMenuItems = [
        ...(hasSufficientGroupPermissions
            ? [
                  {
                      icon: <Users02Icon className="h-5 w-5 shrink-0" />,
                      label: t("user-menu.groups"),
                      href: "/groups",
                  },
              ]
            : []),
        ...(hasSufficientRapportagePermissions
            ? [
                  {
                      icon: (
                          <PresentationChart02 className="h-5 w-5 shrink-0" />
                      ),
                      label: t("user-menu.rapportage"),
                      href: "/rapportage",
                  },
              ]
            : []),
    ];

    return {
        personal: personalMenuItems,
        base: baseMenuItems,
    };
}
